import { AggregationApiClient } from "./aggregation-client";
import { HotelUnitGroupAttributeGroupsDto } from "./aggregation-client/service";

export const fetchAllUnitGroupAttributes = (p?: { language: string }): Promise<HotelUnitGroupAttributeGroupsDto[]> => {
  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerFetchHotelUnitGroupAttributes({
    perPage: 99,
    language: p?.language || "ru",
  })
    .then((items) => {
      return items
        .sort((a, b) => a.priority - b.priority)
        .map((item) => {
          return {
            ...item,
            attributes: item.attributes.sort((a, b) => a.priority - b.priority),
          };
        });
    })
    .catch((__err) => {
      return [];
    });
};
