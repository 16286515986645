import { alpha, useMediaQuery } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/system";

import FormSearchCompact from "./FormSearchCompact";
import { Props } from "./Props";
import { FormSearchSubmitButton } from "./FormSearchSubmitButton";
import { SearchGuestsInputForSearch } from "../../widgets/SearchGuestsInput/SearchGuestsInputForSearch";
// import FormSearchHotelPicker from "./FormSearchHotelPicker";
import { FormSearchDatePickerV2 } from "./widgets/FormSearchDatePickerV2";
import FormSearchCityPicker from "./FormSearchCityPicker";

export const FormSearch = (props: Props) => {
  const { cityIdContext, hotelIdContext, type, isSheet, navigateToSearchPage, onSubmit, hasShadow } = props;
  const isCompact = type === "compact";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isCompact) {
    return <FormSearchCompact {...props} />;
  }

  return (
    <>
      <Stack
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        gap={isSheet ? "16px" : "4px"}
        alignItems={"center"}
        sx={{
          borderRadius: !isCompact && !isSheet ? "16px" : undefined,
          boxShadow:
            hasShadow && !isMobile ? `-20px 20px 40px -8px ${alpha(theme.palette.common.black, 0.14)}` : undefined,
          backgroundColor: "rgba(0,0,0,.33)",
        }}
      >
        {/* TODO: decide how cityIdContext value will behave */}
        {!cityIdContext && !hotelIdContext && (
          <>
            <Stack
              sx={{
                flex: isMobile ? 1 : 1,
                width: isMobile ? "100%" : undefined,
              }}
            >
              <FormSearchCityPicker isSheet={isSheet} cities={undefined} />
            </Stack>
          </>
        )}

        {/* {!hotelIdContext && (
          <Stack
            sx={{
              flex: isMobile ? 1 : 1,
              width: isMobile ? "100%" : undefined,
            }}
          >
            <FormSearchHotelPicker hotels={props.hotels} />
          </Stack>
        )} */}

        <Stack
          sx={{
            flex: isMobile ? 1 : 0.968,
            width: isMobile ? "100%" : undefined,
          }}
        >
          <FormSearchDatePickerV2
            context="search"
            isSheet={isSheet}
            isFirstItem={!!cityIdContext || !!hotelIdContext}
            dateFormat="full"
          />
        </Stack>

        <Stack
          sx={{
            flex: isMobile ? 1 : 0.644,
            width: isMobile ? "100%" : undefined,
          }}
        >
          <SearchGuestsInputForSearch context="search" isSheet={isSheet} />
        </Stack>

        <Stack
          sx={{
            flex: 0,
            width: isMobile ? "100%" : undefined,
          }}
        >
          <FormSearchSubmitButton isSheet={isSheet} navigateToSearchPage={navigateToSearchPage} onClick={onSubmit} />
        </Stack>
      </Stack>
    </>
  );
};

export default FormSearch;
