import { AggregationApiClient } from "./aggregation-client";
import { ExtendedOfferDto, PaginationResultImpl } from "./aggregation-client/service";

type Props = {
  lastId?: string;
  arrivalDate: string;
  departureDate: string;
  cities?: string;
  hotelIds?: string;
  attributeIds?: string;
  adults: number;
  children?: number;
  sortOrder?: string;
  minPrice?: number;
  maxPrice?: number;
  perPage?: number;
  page?: number;
  bannerBlockId?: string;
};

export type SearchOffersResponse = {
  offers: ExtendedOfferDto[];
  paginationResult: PaginationResultImpl;
};

export const DEFAULT_PAGE_INDEX = 0;

export const fetchOffers = (p: Props): Promise<SearchOffersResponse> => {
  const payload: any = {};

  if (p.hotelIds) {
    payload.hotelIds = p.hotelIds;
  }

  if (p.page === undefined) {
    p.page = DEFAULT_PAGE_INDEX;
  }

  return AggregationApiClient.OffersService.offersControllerSearchOffers({
    perPage: 12,
    ...p,
    ...payload,
    language: "en",
    attributes: p.attributeIds,
    arrivalDate: p.arrivalDate,
    departureDate: p.departureDate,
    sortByField: "price",
  })
    .then((data) => {
      if (!data.offers || data.offers.length === 0) {
        return {
          offers: [] as ExtendedOfferDto[],
          paginationResult: {
            hasNext: false,
            currentPage: 0,
            perPage: 0,
            hasPrevious: false,
            totalItems: 0,
            totalPages: 0,
          } as PaginationResultImpl,
        };
      }

      return {
        offers: data.offers.map((offer) => {
          return {
            ...offer,
            unitGroup: {
              ...offer.unitGroup,
              description: offer.unitGroup?.description?.replace(/(<([^>]+)>)/gi, "") || "",
              shortDescription: offer.unitGroup?.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
            },
          } as ExtendedOfferDto;
        }),
        paginationResult: data.paginationResult,
      };
    })
    .catch(() => ({
      offers: [] as ExtendedOfferDto[],
      paginationResult: {
        hasNext: false,
        currentPage: 0,
        perPage: 0,
        hasPrevious: false,
        totalItems: 0,
        totalPages: 0,
      } as PaginationResultImpl,
    }));
};
