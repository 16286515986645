import { AggregationApiClient } from "./aggregation-client";
import { ExtendedOfferDto, PaginationResultImpl } from "./aggregation-client/service";
import { DEFAULT_PAGE_INDEX } from "./fetchOffers";

type Props = {
  lastId?: string;
  hotelId?: string;
  attributeIds?: string;
  language?: string;
  adults: number;
  children?: number;
  perPage?: number;
  page?: number;
};

export type FutureOffersResponse = {
  offers: ExtendedOfferDto[];
  paginationResult: PaginationResultImpl;
};

export const fetchFutureOffers = (p: Props): Promise<FutureOffersResponse> => {
  if (p.page === undefined) {
    p.page = DEFAULT_PAGE_INDEX;
  }

  return (
    p.attributeIds
      ? AggregationApiClient.OffersService.offersControllerCustomFutureOffersV2({
          perPage: 12,
          language: "en",
          ...p,
        })
      : AggregationApiClient.OffersService.offersControllerFutureOffersV2({
          perPage: 12,
          language: "en",
          ...p,
        })
  )
    .then((data) => {
      if (!data || !data.offers || data.offers.length === 0) {
        return {
          offers: [] as ExtendedOfferDto[],
          paginationResult: {
            hasNext: false,
            currentPage: 0,
            hasPrevious: false,
            perPage: 0,
            totalItems: 0,
            totalPages: 0,
          } as PaginationResultImpl,
        };
      }

      return {
        offers: data.offers.map((offer) => {
          return {
            ...offer,
            unitGroup: {
              ...offer.unitGroup,
              description: offer.unitGroup?.description?.replace(/(<([^>]+)>)/gi, "") || "",
              shortDescription: offer.unitGroup?.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
            },
          } as ExtendedOfferDto;
        }),
        paginationResult: data.paginationResult,
      };
    })
    .catch(() => ({
      offers: [] as ExtendedOfferDto[],
      paginationResult: {
        hasNext: false,
        currentPage: 0,
        hasPrevious: false,
        perPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as PaginationResultImpl,
    }));
};
