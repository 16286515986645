import { IUnitGroupListItem } from "../@types/unitGroup";
import { AggregationApiClient } from "./aggregation-client";
import { ExtendedHotelUnitGroupResultDto, PaginationResultImpl } from "./aggregation-client/service";

export type PerPage = 4 | 12 | 24;
export const DEFAULT_PER_PAGE: PerPage = 12;
export const DEFAULT_PAGE_INDEX = 0;

export const fetchUnitGroupListByAttributes = ({
  page = DEFAULT_PAGE_INDEX,
  perPage = DEFAULT_PER_PAGE,
  cityId,
  hotelIds,
  attributes = "",
  hotelAttributes = "",
  sortOrder = "asc",
  sortByField,
}: {
  perPage?: PerPage;
  page?: number;
  cityId?: number;
  hotelIds?: string;
  attributes?: string;
  hotelAttributes?: string;
  sortOrder?: string;
  sortByField?: string;
}): Promise<{
  unitGroups: IUnitGroupListItem[];
  paginationResult: PaginationResultImpl;
}> => {
  if (process.env.NEXT_PUBLIC_APP_ENV === "mock") {
    return Promise.resolve({
      unitGroups: [],
      paginationResult: {
        currentPage: 0,
        hasNext: false,
        perPage: 0,
        totalItems: 0,
        totalPages: 0,
        hasPrevious: false,
      },
    });
  }

  const payload: any = {
    page,
  };

  if (perPage) {
    payload.perPage = perPage;
  }
  if (cityId != null) {
    payload.cities = cityId.toString();
  }

  if (hotelIds) {
    payload.hotelIds = hotelIds;
  }

  if (attributes) {
    payload.attributes = attributes;
  }

  if (sortOrder) {
    payload.sortOrder = sortOrder;
  }

  if (sortByField) {
    payload.sortByField = sortByField;
  }

  if (hotelAttributes) {
    payload.hotelAttributes = hotelAttributes;
  }

  return AggregationApiClient.HotelUnitGroupService.hotelUnitGroupControllerFindUnitGroupsV2({
    language: "en",
    perPage,
    sortByField: "price",
    ...payload,
  })
    .then((res: ExtendedHotelUnitGroupResultDto) => {
      const unitGroups: IUnitGroupListItem[] = res.extendedHotelUnitGroupDtos.map((f) => ({
        ...f,
        description: f.description?.replace(/(<([^>]+)>)/gi, "") || "",
        shortDescription: f.shortDescription?.replace(/(<([^>]+)>)/gi, "") || "",
        link: `/unit/${f.id}`,
      }));

      return {
        unitGroups,
        paginationResult: res.paginationResult,
      };
    })
    .catch(() => ({
      unitGroups: [],
      paginationResult: {
        currentPage: 0,
        hasNext: false,
        perPage: 0,
        totalItems: 0,
        totalPages: 0,
        hasPrevious: false,
      },
    }));
};
